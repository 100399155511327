$(document).ready(function() {

  var $forms = $(".js-validate");
  if ($forms.length){
    grecaptcha.ready(function () {
      console.log('Recaptcha Ready');
      $forms.each(function(i, e) {
        var $form = $(e);
        $form.validate({
          submitHandler: function(form) {
            var $form = $(form);
            grecaptcha.execute('6Ld1Wb4ZAAAAAGA6zytqiSDDHuJuF_Ti8a5sdFl0', {action: $form.find('input[name="handle"]').val()}).then(function (token) {
              console.log('Executing RECAPTCHA');
              var postData = {token: token};
              fetch('//'+window.location.hostname+'/validate-form', {
                method: 'post',
                body: JSON.stringify(postData),
                headers:{
                  'Content-Type': 'application/json'
                }
              }).then(function (response) {
                response.json().then(function (data) {
                  console.log("Rating:"+data.score);
                  if (data.success === true && (data.score >= 0.9)) {
                    console.log('Adding element');
                    $form.prepend('<input type="hidden" name="g-rec-field" value="TRUE">');
                    console.log("submitting");
                    form.submit();
                  }
                });
              });
            });
          },
        });
      });
    });
  }

  if ($(".sign_up_form").length){
    $(".sign_up_form").validate();
  }

  $('.main-banners').slick({
      dots: true,
      infinite: true,
      speed: 500,
      cssEase: 'cubic-bezier(0.86, 0, 0.07, 1)',
      autoplay: true,
      autoplaySpeed: 5000,
  });


  if($("#product_large_images > ul li").length){

    $("#product_large_images > ul").magnificPopup({
      delegate: "a",
      type: "image",
      closeOnContentClick: false,
      closeBtnInside: false,
      mainClass: "mfp-with-zoom mfp-img-mobile",
      image: {
        verticalFit: true,
        navigateByImgClick: true,
      },
      gallery: {
        enabled: true
      },
      zoom: {
        enabled: true,
        duration: 300, // don't foget to change the duration also in CSS
        opener: function(element) {
          return element.find("img");
        }
      }

    });
  }


  if ($("#product_large_images").length){
    $("#product_large_images > ul").slick({
      slide:"li",
      lazyLoad: "ondemand",
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true
    });
  }

  if ($("#product_small_images").length){
    $("#product_small_images > ul").slick({
      slide:"li",
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: "#product_large_images > ul",
      dots: false,
      focusOnSelect: true
    });
  }

  $( ".category_filter" ).click(function() {
    var $filter = $(this).attr('data-filter');
    console.log($filter);
    $('.category_list').isotope({ filter: $filter });
  });

  $('.filters').each( function( i, buttonGroup ) {
    var $buttonGroup = $( buttonGroup );
    $buttonGroup.on( 'click', 'a', function() {
      $buttonGroup.find('.is-checked').removeClass('is-checked');
      $( this ).addClass('is-checked');
    });
  });


  var $container = $('.category_list');
  $container.isotope({
    // options
    itemSelector: '.product_item',
    layoutMode: 'fitRows'
  });

  // tabbed content
  // http://www.entheosweb.com/tutorials/css/tabs.asp
  $(".tab_content").hide();
  $(".tab_content:first").show();


  /* if in tab mode */
  $("ul.tabs li").click(function() {

    $(".tab_content").hide();
    var activeTab = $(this).attr("rel");
    $("#"+activeTab).fadeIn();

    $("ul.tabs li").removeClass("active");
    $(this).addClass("active");

  $(".tab_drawer_heading").removeClass("d_active");
  $(".tab_drawer_heading[rel^='"+activeTab+"']").addClass("d_active");

  });
	/* if in drawer mode */
	$(".tab_drawer_heading").click(function() {

    $(".tab_content").hide();
    var d_activeTab = $(this).attr("rel");
    $("#"+d_activeTab).fadeIn();

    $(".tab_drawer_heading").removeClass("d_active");
      $(this).addClass("d_active");

    $("ul.tabs li").removeClass("active");
    $("ul.tabs li[rel^='"+d_activeTab+"']").addClass("active");
  });


	/* Extra class "tab_last"
	   to add border to right side
	   of last tab */
	$('ul.tabs li').last().addClass("tab_last");


	jQuery(".related_content").hide();
  //toggle the componenet with class msg_body
  jQuery(".expand_btn").click(function()
  {
    jQuery(this).next(".related_content").slideToggle(500);
    jQuery(this).toggleClass("open");
  });

  var $faqsWrapper = $('.js-faqs');
  if ($faqsWrapper.length) {
    var $faqs = $('.c-faqs__item');
    $faqs.each(function(i, e) {
      var $faq = $(e);
      var $faqQ = $faq.find('.c-faqs__q');
      $faqQ.on('click', function() {
        $faq.toggleClass('is-active');
      });
    });
  }


  // ADDS TOGGLE FEATURE TO FAQs CATEGORIES
  var $faqsWrapper = $('.js-faqs');
  if ($faqsWrapper.length) {
    var $faqsGroups = $('.js-faqs-group');
    $faqsGroups.each(function(i, e) {
      var $faqsGroup = $(e);
      var $faqsGroupSubheading = $faqsGroup.find('.l-faqs__subheading');
      var $faqsGroupList = $faqsGroup.find('.c-faqs');
      $faqsGroupList.slideUp(0);
      $faqsGroupSubheading.on('click', function() {
        $faqsGroupSubheading.toggleClass('is-active-subheading');
        $faqsGroupList.slideToggle(300);
      });
    });
  }

});
